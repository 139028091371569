import React, { useState, useReducer, useEffect, useRef } from 'react';
import SEO from 'components/seo';
import { Form, Formik } from 'formik';
import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  getRegisterToBidInitialValues,
  getRegisterToBidSchema,
} from 'components/utils/formUtils';
import Layout from 'components/Layout';
import MultiStepForm from 'privatePages/OnlineRegistration/MultiStepForm';
import Spinner from 'components/Spinner';
import './online-registration.scss';
import PrivatePolicyModal from 'components/UpdatedPrivacyPolicyModal';
import useStaticQueryGetPrivacyPolicyDate from 'services/graphql/queries/register-to-bid/privacyPolicyDate';
import {
  useAuthenticatedQuery,
  useAuthenticatedMutation,
  useAuthenticatedLazyQuery,
} from 'services/graphql/hooks';
import {
  activeSignedStepperSteps,
  steps,
} from 'utils/registrationStepsConstants';
import {
  checkFinancialDateOutdated,
  checkPersonalDateOutdated,
  getConditionsOfSale,
} from './register-to-bid.utils';
import { checkoutSubmit } from './checkout';
import { uploadDocuments, registerToBid } from './submitRegistration';
import {
  GET_REGISTER_TO_BID_DATA,
  REGISTER_TO_BID,
  KIOSK_REGISTER_TO_BID,
  ACTIVE_SIGHNED_TERMS_AND_CONDITIONS,
} from 'services/graphql/queries/register-to-bid';
import {
  GET_ASSET_PRESIGNED_UPLOAD_URLS,
  GET_ADMIN_ASSET_PRESIGNED_UPLOAD_URLS,
} from 'services/graphql/queries/assets';
import { PAYMENT_CHECKOUT } from 'services/graphql/queries/Checkout';
import Alert from 'components/Alert';
import Chevron from 'components/Chevron/Chevron';
import Modal from 'components/Modal';
import { useRegistrationAuctions } from 'services/graphql/hooks/RegistrationAuctions';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';
import { KIOSK_REGISTER_PATH } from 'utils/pathsConstants';
// import * as amplitude from '@amplitude/analytics-browser';
// import { registerToBidEvent } from 'utils/amplitude';
import { Stepper } from 'components/Stepper';
const validationSchema = getRegisterToBidSchema();

export default ({ path }) => {
  const [isKiosk] = useState(path === `/${KIOSK_REGISTER_PATH}`);
  const [step, setStep] = useState(isKiosk ? steps.EMAIL : steps.USER_INFO);
  const [prevSteps, setPrevSteps] = useState([]);
  const [cost, setCost] = useState(0);
  const [transparentLoader, setTransparentLoader] = useState(false);
  const [userInfoUpdated, setUserInfoUpdated] = useState(false);
  const [isFinancialDateOutdated, setFinancialDataOutdated] = useState(null);
  const [isPersonalDateOutdated, setPersonalDataOutdated] = useState(false);
  const [uploadingAssets, setUploadingAssets] = useState(false);
  const [userInitialValues, setUserInitialValues] = useState();
  const [showUpdatedPrivacyPolicy, setUpdatedPrivacyPolicy] = useState(false);
  const [stripeInvalid, setStripeInvalid] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const [modalError, setModalError] = useState(null);
  const [kioskModalMessage, setKioskModalMessage] = useState(null);
  const [bidderSalesforceId, setBidderSalesforceId] = useState();
  const [chevronStep, dispatchChevronStep] = useReducer(chevronReducer, 1);
  const [isPersonalBidding, setIsPersonalBidding] = useState(false);
  const [chosenBiddingMethod, setChosenBiddingMethod] = useState('');
  const [region, setRegion] = useState('US');
  const [successfulPayment, setSuccessfulPayment] = useState();
  const nextButtonRef = useRef(null);

  const stripe = useStripe();
  const elements = useElements();

  const { auctions } = useRegistrationAuctions();
  const slugs = useWebPagesSlugs();
  const { privacyPolicy } = useStaticQueryGetPrivacyPolicyDate();

  const {
    loading: registrationDataLoading,
    data: registrationData,
    error: registrationDataError,
  } = useAuthenticatedQuery(GET_REGISTER_TO_BID_DATA, {
    skip: isKiosk,
  });

  const { data: checkSignedTerms, refetch } = useAuthenticatedQuery(
    ACTIVE_SIGHNED_TERMS_AND_CONDITIONS,
    {
      variables: {
        region: region,
      },
    }
  );
  const changeRegion = conditionOfSaleValue => {
    const newRegion =
      conditionOfSaleValue[0] === 'US Conditions of sale' ? 'US' : 'UK';

    if (newRegion !== region) setRegion(newRegion);
  };
  useEffect(() => {
    if (checkSignedTerms && region) refetch();
  }, [region, checkSignedTerms, refetch]);
  const [
    getUploadURLs,
    { error: uploadURLsError, loading: uploadURLsLoading },
  ] = useAuthenticatedMutation(
    isKiosk
      ? GET_ADMIN_ASSET_PRESIGNED_UPLOAD_URLS
      : GET_ASSET_PRESIGNED_UPLOAD_URLS
  );

  const [
    CreateStripeIntent,
    { error: checkoutError },
  ] = useAuthenticatedMutation(PAYMENT_CHECKOUT);

  const [
    register,
    { error: registrationError, loading: registrationLoading },
  ] = useAuthenticatedMutation(
    isKiosk ? KIOSK_REGISTER_TO_BID : REGISTER_TO_BID
  );

  useEffect(() => {
    if (isKiosk)
      setUserInitialValues(getRegisterToBidInitialValues({}, isKiosk));
  }, []);

  useEffect(() => {
    if (registrationData) {
      const user = registrationData.profile;
      setUserInitialValues(getRegisterToBidInitialValues(user), isKiosk);
      setUpdatedPrivacyPolicy(
        !user.privacyPolicyAcceptanceDate ||
          new Date(user.privacyPolicyAcceptanceDate) <
            new Date(privacyPolicy.updatedAt)
      );
    }
  }, [registrationData]);

  useEffect(() => {
    setModalError(checkoutError);
  }, [checkoutError]);

  const checkPersonalDateOutdatedHandler = (selectedAuctions = []) => {
    const auctionsDetails = selectedAuctions.map(auction => {
      return auctions.find(x => x.objectID === auction);
    });
    const checkPersonal = checkPersonalDateOutdated(
      userInitialValues?.personalDocumentSubmissionDate,
      userInitialValues?.buyerStatus,
      auctionsDetails
    );
    setPersonalDataOutdated(checkPersonal);
  };

  const checkFinancialDateOutdatedHandler = (selectedAuctions = []) => {
    const auctionsDetails = selectedAuctions.map(auction => {
      return auctions.find(x => x.objectID === auction);
    });

    const checkFinancial = checkFinancialDateOutdated(
      userInitialValues?.financialDocumentSubmissionDate,
      userInitialValues?.buyerStatus,
      auctionsDetails
    );
    setFinancialDataOutdated(checkFinancial);
  };

  const dispatchPrevSteps = action => {
    switch (action.type) {
      case 'push':
        setPrevSteps([...prevSteps, action.payload]);
        return '';
      case 'pop':
        const copy = [...prevSteps];
        const lastItem = copy.pop();
        setPrevSteps(copy);
        return lastItem;
      // case 'pop-payment':

      default:
        return '';
    }
  };

  const isPaymentValid = isValid => isValid && (!cost || !stripeInvalid);

  const checkOut = async (values, isValid) => {
    if (!(cost && isPaymentValid(isValid))) return;
    try {
      await checkoutSubmit(
        values,
        setTransparentLoader,
        setModalError,
        stripe,
        elements,
        CardNumberElement,
        CreateStripeIntent,
        cost
      );
      return true;
    } catch (error) {
      setModalError(error);
      return false;
    }
  };

  const handleDoneClick = event => {
    if (event.key.toLowerCase() === 'enter' || event.keyCode === 13) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleSubmit = async (values, isValid = true) => {
    try {
      const uploadMeta = await uploadDocuments(
        values,
        getUploadURLs,
        setUploadingAssets,
        isKiosk,
        bidderSalesforceId
      );
      const checkedOut = !isKiosk ? await checkOut(values, isValid) : true;
      if (checkedOut !== false) {
        if (successfulPayment !== false) {
          // register if true (payment successful) or null (no payment needed)
          await registerToBid(
            values,
            auctions,
            register,
            userInfoUpdated,
            isFinancialDateOutdated,
            cost,
            isPersonalDateOutdated,
            slugs[SLUGS.registerToBid],
            isKiosk,
            bidderSalesforceId,
            setStep,
            uploadMeta
          );
          // amplitude.track('Submit register to bid form', {
          //   email_address: values.email,
          // });
          window.fbq('track', 'Register To Bid');
        }
      }
    } catch (error) {
      setAlertError(error);
      setTransparentLoader(false);
    }
  };

  const handleStartOverButton = () => {
    setUserInitialValues(getRegisterToBidInitialValues({}));
    setBidderSalesforceId(null);
    setCost(0);
    setStep(steps.EMAIL);
  };
  const stepsWithNoChevron = [steps.KIOSK_PAYMENT, 'kioskConfirmation'];

  const showAlert =
    registrationDataError || registrationError || uploadURLsError || alertError;

  const showLoader = registrationDataLoading || !userInitialValues;
  return (
    <Layout
      hideFooter={true}
      hideHeaderItems={isKiosk}
      disableLogoClick={isKiosk}
    >
      <SEO title="Online Registration" />
      {showUpdatedPrivacyPolicy && <PrivatePolicyModal />}
      {showAlert && (
        <Alert color="danger" position="top" msg={showAlert.toString()} />
      )}
      {showLoader ? (
        <Spinner />
      ) : (
        <>
          {!stepsWithNoChevron.includes(step) && (
            <Chevron amount={chevronStep} />
          )}
          <Stepper
            isPersonalBidding={isPersonalBidding}
            activeStep={step}
            prevSteps={prevSteps}
            biddingMethod={chosenBiddingMethod}
            activeSignedTermsAndConditions={
              checkSignedTerms?.checkActiveSignedTermsAndConditions
            }
            isFinancialDateOutdated={isFinancialDateOutdated}
          />
          <div className="online-registration">
            <div className="body">
              {(transparentLoader ||
                registrationLoading ||
                uploadURLsLoading ||
                uploadingAssets) && <Spinner />}
              <Formik
                validateOnMount
                initialValues={userInitialValues}
                validationSchema={validationSchema[step]}
                onSubmit={values => {}}
                enableReinitialize
              >
                {props => {
                  return (
                    <>
                      <Form className="form-flex">
                        <MultiStepForm
                          {...props}
                          step={step}
                          setStep={setStep}
                          setTransparentLoader={setTransparentLoader}
                          auctions={auctions}
                          setUserInfoUpdated={setUserInfoUpdated}
                          initialValues={userInitialValues}
                          isFinancialDateOutdated={isFinancialDateOutdated}
                          handleSubmit={handleSubmit}
                          dispatchChevronStep={dispatchChevronStep}
                          setFieldTouched={props.setFieldTouched}
                          isPersonalDateOutdated={isPersonalDateOutdated}
                          checkPersonalDateOutdatedHandler={
                            checkPersonalDateOutdatedHandler
                          }
                          dispatchPrevSteps={dispatchPrevSteps}
                          prevSteps={prevSteps}
                          checkFinancialDateOutdatedHandler={
                            checkFinancialDateOutdatedHandler
                          }
                          cost={cost}
                          setCost={setCost}
                          setStripeInvalid={setStripeInvalid}
                          isPaymentValid={isPaymentValid}
                          registerToBidSlug={slugs[SLUGS.registerToBid]}
                          isKiosk={isKiosk}
                          setKioskModalMessage={setKioskModalMessage}
                          setUserInitialValues={setUserInitialValues}
                          setAlertError={setAlertError}
                          bidderSalesforceId={bidderSalesforceId}
                          setBidderSalesforceId={setBidderSalesforceId}
                          slugs={slugs}
                          handleStartOverButton={handleStartOverButton}
                          handleDoneClick={handleDoneClick}
                          nextButtonRef={nextButtonRef}
                          setIsPersonalBidding={setIsPersonalBidding}
                          setChosenBiddingMethod={setChosenBiddingMethod}
                          activeSignedTermsAndConditions={
                            checkSignedTerms?.checkActiveSignedTermsAndConditions
                          }
                          changeRegion={changeRegion}
                          checkOut={checkOut}
                          setSuccessfulPayment={setSuccessfulPayment}
                        />
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
            {/* {step < 4 && <Navigation step={step} maxStep={6} />} */}
            {modalError && (
              <Modal
                title="Payment failed"
                bodyText="Unfortunately, your payment failed. Please try again or attempt with a different credit card."
                isOpen={modalError ? true : false}
              />
            )}
            {kioskModalMessage && (
              <Modal
                title={kioskModalMessage.title}
                bodyText={kioskModalMessage.text}
                isOpen={kioskModalMessage}
                disableToggling={kioskModalMessage.disableToggling}
                resetState={setKioskModalMessage}
              />
            )}
          </div>
        </>
      )}
    </Layout>
  );
};

function chevronReducer(count, action) {
  switch (action) {
    case 'next':
      return count + 1;
    case 'back':
      return count - 1;
    default:
      return count;
  }
}
