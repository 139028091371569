import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import Loading from 'components/Spinner';
import { useAuth0 } from '@auth0/auth0-react';
// import * as amplitude from '@amplitude/analytics-browser';
const Login = () => {
  const { isLoading, isAuthenticated, loginWithRedirect, user } = useAuth0();
  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    loginWithRedirect({
      appState: { targetUrl: '/' },
    });
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  if (isAuthenticated) {
    // amplitude.track('Successful log in', { email_address: user?.email });
    // amplitude.setUserId(user?.email);
    navigate('/');
  }
  return <Loading />;
};

export default Login;
